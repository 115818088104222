
.exam-content {
    height: 100%;
    width: 100%;
    background: linear-gradient(56deg, #070932 0%, #12144D 100%);

    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;

        .el-scrollbar__view {
            height: 100%;
        }
    }

    .main-content {
        width: 500px;
        margin: 0 auto;
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.top-content {
    font-size: 18px;
    display: flex;
    align-items: center;

    i {
        font-size: 22px;
        color: #FF3E6C;
        margin-right: 11px;
    }

    .top-title {
        height: 24px;
        color: #FFFFFF;
    }

    .top-time {
        color: #FF3E6C;
        height: 24px;
        line-height: 28px;
    }
}

.middle-content {
    width: calc(100% - 72px);
    margin: 50px 36px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-text {
        font-size: 30px;
        font-weight: 500;
        color: #FFFFFF;
    }
}

.title-item {
    width: 94px;
    height: 1px;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    position: relative;

    &.title-left:after {
        position: absolute;
        content: '';
        height: 13px;
        width: 13px;
        border-radius: 50%;
        right: -2px;
        top: -5px;
        background-color: #fff;
    }

    &.title-right:before {
        position: absolute;
        content: '';
        height: 13px;
        width: 13px;
        border-radius: 50%;
        left: -2px;
        top: -5px;
        background-color: #fff;
    }
}

.img-content {
    position: relative;

    .tip-content {
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        margin-top: -50px;
        font-size: 30px;
        color: #0E1042;
        font-weight: 500;
        line-height: 48px;

        .tip-top {
            text-align: center;
        }
    }
}

.footer-content {
    width: calc(100% - 72px);
    margin: -60px 36px 0;
    display: flex;
    justify-content: space-between;

    .el-button {
        width: 200px;
    }
}

.count-down {
    background-color: unset;
}
